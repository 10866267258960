import { Alert, Autocomplete, Grid, TextField, Typography } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers-pro";
import moment, { Moment } from "moment";
import { Fragment, useCallback, useContext, useEffect, useState } from "react";
import { FileMeta, FileUploadDropzone, FileViewer, useDeleteFiles, useGetFileMetas, useUploadFile } from "wcz-file";
import { LayoutContext, newGuid } from "wcz-layout";
import App from "../../../models/App";
import Platform from "../../../models/Platform";
import Release from "../../../models/Release";
import { AppType } from "../../../models/enums/AppType";
import { PlatformType } from "../../../models/enums/PlatformType";
import { ReleaseEnvironment } from "../../../models/enums/ReleaseEnvironment";
import { getPlistContent } from "../../../utils/Helpers";

interface ReleaseFormProps {
    release: Release;
    setRelease: (release: Release) => void;
    platform: Platform;
    app: App;
}

export const ReleaseForm: React.FC<ReleaseFormProps> = ({ release, setRelease, platform, app }) => {
    const { t } = useContext(LayoutContext);
    const [needUploadPlist, setNeedUploadPlist] = useState(false);

    const handleOnEnvironmentChange = useCallback((event: React.SyntheticEvent<Element, Event>, value: ReleaseEnvironment) => {
        if (value === release.environment) return;

        const previousRelease = value === ReleaseEnvironment.Production ? platform.latestProductionRelease : platform.latestDevelopmentRelease;

        setRelease({
            ...release,
            environment: value,
            version: previousRelease?.version ?? "",
            value: app.type === AppType.Web ? previousRelease?.value ?? "" : newGuid(),
        });
    }, [release, app, platform]);

    const handleOnVersionChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => setRelease({ ...release, version: event.target.value }), [release]);
    const handleOnDateChange = useCallback((newValue: Moment | null) => newValue && setRelease({ ...release, releaseDate: newValue.format() }), [release]);
    const handleOnReleaseNotesChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => setRelease({ ...release, releaseNotes: event.target.value }), [release]);
    const handleOnValueChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => setRelease({ ...release, value: event.target.value }), [release]);

    const { data: fileMetas = [] } = useGetFileMetas(release.value, {
        enabled: !!release.id && (platform.type === PlatformType.Android || platform.type === PlatformType.Iphone)
    });

    useEffect(() => {
        if (fileMetas.length && needUploadPlist) {
            handleUploadPlist(fileMetas[0]);
        }
    }, [fileMetas, needUploadPlist]);

    const handleOnFileUpload = useCallback(() => {
        if (platform.type === PlatformType.Iphone) {
            setNeedUploadPlist(true);
        }
    }, [platform, fileMetas, release, app]);

    const { mutate: uploadPlist } = useUploadFile({ subId: `${release.value}-plist`, onSuccess: () => setNeedUploadPlist(false), onError: () => deleteFiles(release.value) });

    const handleUploadPlist = useCallback((fileMeta: FileMeta) => {
        const plistContent = getPlistContent(fileMeta.id, release, app.title);
        const plistBlob = new Blob([plistContent], { type: "application/xml" });
        const plistFile = new File([plistBlob], `${app.title}.plist`, { type: "application/xml" });
        return uploadPlist(plistFile);
    }, [fileMetas, release, app]);

    const { mutate: deleteFiles } = useDeleteFiles();

    const handleOnFileDelete = useCallback(() => {
        if (platform.type === PlatformType.Iphone) {
            deleteFiles(`${release.value}-plist`);
        }
    }, [platform]);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Autocomplete
                    value={release.environment ?? null}
                    options={Object.values(ReleaseEnvironment)}
                    getOptionLabel={(option) => t(option)}
                    disableClearable
                    onChange={handleOnEnvironmentChange}
                    renderInput={(params) => <TextField {...params} label={t("Environment")} fullWidth variant="standard" required />}
                />
            </Grid>

            <Grid item xs={12}>
                <TextField label={t("Version")} value={release.version ?? ""} onChange={handleOnVersionChange} fullWidth required variant="standard" />
            </Grid>

            <Grid item xs={12}>
                <DateTimePicker label={t("ReleaseDate")} value={moment(release.releaseDate)} onChange={handleOnDateChange} slotProps={{
                    textField: {
                        variant: "standard",
                        fullWidth: true,
                        required: true
                    }
                }} />
            </Grid>

            <Grid item xs={12}>
                <TextField label={t("ReleaseNotes")} value={release.releaseNotes ?? ""} onChange={handleOnReleaseNotesChange} fullWidth variant="standard" multiline required />
            </Grid>

            <Grid item xs={12}>
                {(platform.type === PlatformType.Web || platform.type === PlatformType.PowerApps) ?
                    <TextField label={t("Link")} value={release.value ?? ""} onChange={handleOnValueChange} fullWidth multiline variant="standard" />
                    :
                    <Fragment>
                        <Typography gutterBottom>{t("Application")}</Typography>
                        {fileMetas.length ?
                            <FileViewer subId={release.value ?? ""} onDelete={handleOnFileDelete} />
                            :
                            <FileUploadDropzone subId={release.value} maxFiles={1} onSuccess={handleOnFileUpload} />
                        }
                    </Fragment>
                }
            </Grid>

            {moment(release.releaseDate).isAfter(moment()) &&
                <Grid item xs={12}>
                    <Alert severity="info" sx={{ width: "100%" }}>{t("ReleaseDateFutureInfo")}</Alert>
                </Grid>
            }
        </Grid>
    );
};